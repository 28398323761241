/* You can add global styles to this file, and also import other style files */
@use '~@angular/material' as mat;
@import 'theme';
@import 'material';
@import 'normalize';
@import 'fonts';

@import '~swiper/scss';
@import '~swiper/scss/grid';
@import '~swiper/scss/pagination';

html {
  font: 400 14px / 20px Noto Serif JP, Noto Sans JP, Noto Sans KR, sans-serif;
  // font-family: Meiryo UI, Noto Sans JP, Noto Sans KR, sans-serif;
  -webkit-tap-highlight-color: transparent;
}

button {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

/* :target::before {
  content: '';
  display: block;
  height: 210px;
  margin-top: -210px;
} */

// 모바일 앱의 경우
.mobile-app {
  * {
    // 드래그로 요소 선택 불가
    // user-select: none;
    // 링크 롱 터치 불가
    -webkit-touch-callout: none;
  }
}

// 크롬 스크롤 복원용 래퍼
main[wrapper] {
  min-height: 100vh;
  background-color: #fff;
}

span.large {
  & > {
    p {
      margin-bottom: 0;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1;
      margin-bottom: 0;
    }
    h1 {
      font-size: 82px;
    }
    h2 {
      font-size: 68px;
    }
    h3 {
      font-size: 54px;
    }
    h4 {
      font-size: 40px;
    }
    h5 {
      font-size: 26px;
    }
    h6 {
      font-size: 12px;
    }
  }
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 74px;
  #{--}swiper: {
    pagination: {
      bullet: {
        horizontal-gap: 15.65px;
        width: 16px;
        height: 16px;
        inactive: {
          opacity: 1;
          color: transparent;
        }
      }
      color: $theme-primary-500;
    }
  }

  .swiper-pagination-bullet {
    box-sizing: border-box;
  }

  .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
    border: 2px solid #fff;
  }

  @at-root .small & {
    bottom: 12px;
    #{--}swiper: {
      pagination: {
        bullet: {
          horizontal-gap: 5.9px;
          width: 6px;
          height: 6px;
          inactive: {
            opacity: 0.5;
            color: #fff;
          }
        }
      }
    }
  }
}

.menu-card {
  position: relative;

  .swiper-slide {
    width: 100% !important;
    height: 100% !important;
  }
  &.small {
    .swiper-slide {
      height: calc((100% - 40px) / 2) !important;
    }
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      #{--}swiper: {
        pagination: {
          bullet: {
            inactive: {
              opacity: 1;
              color: #f0f0f0;
            }
          }
        }
      }
      bottom: 0px;
      margin: 16px 0;
      display: flex;
      justify-content: center;
    }
  }
  .goods-slide .swiper-slide {
    width: 100% !important;
    height: 100% !important;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 45px;
    .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
      border: 2px solid #f0f0f0;
    }
  }
}

.mat-ripple[fxLayoutGap] {
  .mat-ripple-element {
    margin: 0 !important;
  }
  :nth-last-child(2):has(+ .mat-ripple-element) {
    margin-bottom: 0 !important;
    margin-right: 0 !important;
  }
}

.goods-detail-dialog {
  max-width: 60.5vw !important;
  .mat-dialog-container {
    border-radius: 0;
  }
  @media (max-width: 960px) {
    max-width: none !important;
    width: 100%;
    height: 100%;
    .mat-dialog-container {
      padding: 0;
    }
  }
}

// ios safearea top, bottom, left, right
[safearea] {
  @extend [safe-top];
  @extend [safe-bottom];
  @extend [safe-side];
}

// ios safearea top
[safe-top] {
  @supports (padding-top: constant(safe-area-inset-top)) {
    padding-top: constant(safe-area-inset-top);
  }
  @supports (padding-top: env(safe-area-inset-top)) {
    padding-top: env(safe-area-inset-top);
  }
}

// ios safearea bottom
[safe-bottom] {
  @supports (padding-top: constant(safe-area-inset-top)) {
    padding-bottom: constant(safe-area-inset-bottom);
  }
  @supports (padding-top: env(safe-area-inset-top)) {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

// ios safearea left, right
[safe-side] {
  @supports (padding-top: constant(safe-area-inset-top)) {
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
  }
  @supports (padding-top: env(safe-area-inset-top)) {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}
