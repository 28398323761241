// Noto KR
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap);

// Noto JP
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap);

// Material Symbols
@import url(https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200);

// Noto Serif JP
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@100;300;400;500;700;900&display=swap');

// Nanum Sqaure
// @import url(https://hangeul.pstatic.net/hangeul_static/css/nanum-square.css);
@font-face {
  font: {
    family: 'NanumSquare';
    weight: 300;
  }
  src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareL.woff)
      format('woff'),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareL.ttf)
      format('truetype');
}

@font-face {
  font: {
    family: 'NanumSquare';
    weight: normal;
  }
  src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareR.woff)
      format('woff'),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareR.ttf)
      format('truetype');
}

@font-face {
  font: {
    family: 'NanumSquare';
    weight: bold;
  }
  src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareB.woff)
      format('woff'),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareB.ttf)
      format('truetype');
}

@font-face {
  font: {
    family: 'NanumSquare';
    weight: 800;
  }
  src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareEB.woff)
      format('woff'),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareEB.ttf)
      format('truetype');
}

@font-face {
  font: {
    family: 'GmarketSans';
    weight: 300;
  }
  src: url(/assets/fonts/GmarketSans/Light.woff2);
}

@font-face {
  font: {
    family: 'GmarketSans';
    weight: 500;
  }
  src: url(/assets/fonts/GmarketSans/Medium.woff2);
}

@font-face {
  font: {
    family: 'GmarketSans';
    weight: bold;
  }
  src: url(/assets/fonts/GmarketSans/Bold.woff2);
}

@font-face {
  font-family: 'Meiryo UI';
  font-weight: normal;
  src: local('Meiryo UI'), url(/assets/fonts/MeiryoUI.woff2) format('woff2');
  // 일어, 한자, 일본어 한자, 영문 전각, 기호
  unicode-range: U+3041-4096, U+30A0-30FF, U+3400-4DB5, U+4E00-9FCB, U+F900-FA6A,
    U+2E80-2FD5, U+FF5F-FF9F, U+31F0-31FF, U+3220-3243, U+3280-337F, U+FF01-FF5E;
}
