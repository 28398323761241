@function getContrastColor($color) {
  $result: white;
  @if (lightness($color) > 50%) {
    $result: rgba(black, 0.87);
  }
  @return $result;
}

@function theme($color-500) {
  @return (
    50: lighten($color-500, 52%),
    100: lighten($color-500, 37%),
    200: lighten($color-500, 26%),
    300: lighten($color-500, 12%),
    400: lighten($color-500, 6%),
    500: $color-500,
    600: darken($color-500, 6%),
    700: darken($color-500, 12%),
    800: darken($color-500, 18%),
    900: darken($color-500, 24%),
    A100: saturate(lighten($color-500, 50%), 30%),
    A200: saturate(lighten($color-500, 30%), 30%),
    A400: saturate(lighten($color-500, 10%), 15%),
    A700: saturate(lighten($color-500, 5%), 5%),
    contrast: (
      50: getContrastColor(lighten($color-500, 52%)),
      100: getContrastColor(lighten($color-500, 37%)),
      200: getContrastColor(lighten($color-500, 26%)),
      300: getContrastColor(lighten($color-500, 12%)),
      400: getContrastColor(lighten($color-500, 6%)),
      500: getContrastColor($color-500),
      600: getContrastColor(darken($color-500, 6%)),
      700: getContrastColor(darken($color-500, 12%)),
      800: getContrastColor(darken($color-500, 18%)),
      900: getContrastColor(darken($color-500, 24%)),
      A100: getContrastColor(saturate(lighten($color-500, 50%), 30%)),
      A200: getContrastColor(saturate(lighten($color-500, 30%), 30%)),
      A400: getContrastColor(saturate(lighten($color-500, 10%), 15%)),
      A700: getContrastColor(saturate(lighten($color-500, 5%), 5%)),
    )
  );
}

// 라이트 테마 기준 변수
$theme-primary-500: #db3639;
$theme-secondary-500: #111111;
$theme-warn-500: white;

// 다크 테마 기준 변수
$theme-primary-dark-500: white;
$theme-secondary-dark-500: #db2128;
$theme-warn-dark-500: black;

// 라이트 테마
$theme-primary: theme($theme-primary-500);
$theme-secondary: theme($theme-secondary-500);
$theme-warn: theme($theme-warn-500);

// 다크 테마
$theme-primary-dark: theme($theme-primary-dark-500);
$theme-secondary-dark: theme($theme-secondary-dark-500);
$theme-warn-dark: theme($theme-warn-dark-500);

// 테마 설정 변수
$primary: $theme-primary;
$secondary: $theme-secondary;
$warn: $theme-warn;

// 다크 모드 설정
@if (not($theme-primary-dark == null)) {
  $primary: $theme-primary-dark;
}

@if (not($theme-secondary-dark == null)) {
  $secondary: $theme-secondary-dark;
}

@if (not($theme-warn-dark == null)) {
  $warn: $theme-warn-dark;
}
